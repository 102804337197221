<template>
    <div class="leads-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="row g-2 mb-4">
                <div class="col-md-12 col-lg-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search leads"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <div class="col-md-2">
                    <ui-select-multiple
                        v-if="advisorFilterList"
                        id="advisorFilter"
                        name="advisorFilter"
                        label="Advisor"
                        class="w-100"
                        option-label="name"
                        option-value="code"
                        v-model:value="filters.advisor_id"
                        :options="advisorFilterList"
                    />
                </div>
                <div class="col-md-2">
                    <ui-select-multiple
                        id="stageFilter"
                        name="stageFilter"
                        label="Stage"
                        class="w-100"
                        option-label="label"
                        option-value="value"
                        v-model:value="filters.stage"
                        :options="stages"
                    />
                </div>
                <div class="col-auto ms-auto" v-if="CAN(['write_leads'])">
                    <ui-button variant="primary" to="/leads/register">
                        Add lead
                    </ui-button>
                </div>
            </div>
            <ui-table class="mb-4" :items="accounts" :fields="fields" :busy="busy" v-model:sort-by="filters.sort_by" v-model:sort-type="filters.sort_type" :scopes="['write_leads']">
                <template #cell(name)="data">
                    <ui-link
                        v-if="data.item.first_name"
                        :to="{ name: 'leads-info', query: { id: data.item.id } }"
                    >
                        {{ data.item.first_name }} {{ data.item.last_name || '' }}
                    </ui-link>
                    <span v-else class="text-muted fw-bold fst-italic"> - </span>
                    <p class="mb-0">
                        <strong class="account-list__strong">ID:</strong> empty
                    </p>
                </template>
                <template #cell(advisor)="data">
                    <ui-link
                        v-if="data.item.advisor_id"
                        :to="`admins/${data.item.advisor_id}/edit`"
                        weight="regular"
                    >
                        {{ data.item.advisor.first_name }} {{ data.item.advisor.last_name || '' }}
                    </ui-link>
                </template>
                <template #cell(website)="data">
                    <a v-sanitize-href="data.item.webpage" target="_blank" v-if="data.item.webpage" >
                        {{ data.item.webpage }}
                    </a>
                </template>
                <template #cell(link_invitation)="data">
                    <link-invitation 
                        v-if="data.item.link_invitation" 
                        :link="data.item.link_invitation" 
                        :textLength="15"
                        :leadId="data.item.id"
                    />
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('L') }}
                    <br />
                    {{ $dayjs(data.item.created_at).format('LT') }}
                </template>
                <template #cell(contact)="data">
                    <div class="account-list__contact-buttons">
                        <contact-links
                            :phone="data.item.phone || null"
                            :email="data.item.email || null"
                        />
                    </div>
                </template>
                <template #cell(tracking)="data">
                    <ui-stage-status 
                        class="account-list__stage-status"
                        :stage="data.item.stage || 'NA'"
                        @click="$refs.commentsModal.show(data.item.id)"
                    />
                </template>
                <template #cell(status)="data">
                    <ui-status class="fs-13px" :status="data.item.status" v-if="data.item.status" />
                </template>
                <template #cell(actions)="data" >
                    <div class="d-flex">
                        <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                            <template #button-content>
                                <span class="fas fa-ellipsis-v"></span>
                            </template>
                            <b-dropdown-item @click="setleadIdLink(data.item.id)">
                                Send invitation link
                            </b-dropdown-item>
                            <b-dropdown-item @click="openAdvisorModal(data.item.id)" v-if="advisorFilterList">
                                Update advisor
                            </b-dropdown-item>
                            <b-dropdown-item @click="editLead(data.item.id)">
                                Edit
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteLead(data.item.id)">
                                Delete
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </template> 
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
        <modal ref="delete-modal" centered title="Confirm delete" backdrop="static" :keyboard="false" hide-header-close>            
            <div>
                <p>Are you sure you want to delete this lead?</p>
            </div>
            <template #footer>
                <ui-button @click="closeConfirmModal()" :disable="sending" >Cancel</ui-button>
                <ui-button @click="confirmDelete()" variant="danger" :loading="sending" >Delete</ui-button>
            </template>
        </modal>
        <modal ref="advisor-modal" centered title="Update advisor" backdrop="static" :keyboard="false" hide-header-close>                 
            <div>
                <p>
                    Select an option from the list below:
                </p>
                <ui-select-multiple
                    v-if="advisorFilterList"
                    id="advisorSelect"
                    name="advisorSelect"
                    label="Select"
                    class="mt-2"
                    option-label="name"
                    option-value="code"
                    v-model:value="advisorSelectedForUpdateLeads"
                    :options="advisorFilterList"
                />
                
            </div>
            <template #footer>
                <ui-button @click="closeAdvisorModal()" :disabled="sending">Cancel</ui-button>
                <ui-button @click="updateLeadAdvisor()" variant="primary" 
                    :loading="sending" :disabled="!advisorSelectedForUpdateLeads || advisorSelectedForUpdateLeads === ''">
                    Save
                </ui-button>
            </template>
        </modal>
        <modal ref="send-email-modal" centered title="Send link" backdrop="static" :keyboard="false" hide-header-close @hidden="resetModal()">            
            <div>
                <p>Send invitation link to lead?</p>
            </div>
            <template #footer>
                <ui-button @click="closeModal()" :disabled="sending" >Cancel</ui-button>
                <ui-button variant="primary" @click="sendInvitationLink()" :loading="sending">Send</ui-button>
            </template>
        </modal>
        <comments-modal ref="commentsModal" is-lead  @updateStage="updateStage" :scope="'write_leads'"/>
    </div>
</template>

<script>
import CommentsModal from '@/components/accounts/CommentsModal.vue';
import ContactLinks from '@/components/ui/ContactLinks';
import LinkInvitation from '@/components/leads/LinkInvitation.vue';
import Modal from '@/components/ui/Modal.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link';
import UiPagination from '@/components/ui/Pagination';
import UiStageStatus from '@/components/ui/StageStatus';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';

import table_mixin from '@/mixins/tables.mixin';

import { stages } from '@/data';

export default {
    components: {
        CommentsModal,
        ContactLinks,
        Modal,
        LinkInvitation,
        UiButton,
        UiInput,
        UiLink,
        UiPagination,
        UiStageStatus,
        UiStatus,
        UiTable,
        UiTableSkeleton,
        UiSelectMultiple,
    },
    mixins: [table_mixin],
    data() {
        return {
            accounts: null,
            advisorFilterList: null,
            advisorSelectedForUpdateLeads: '',
            advisorsList: [],
            busy: false,
            fields: [
                {
                    key: 'name',
                    label: 'Name'
                },
                {
                    key: 'advisor',
                    label: 'Advisor'
                },
                {
                    key: 'website',
                    label: 'Website',
                },
                {
                    key: 'link_invitation',
                    label: 'Link invitation',
                },
                {
                    key: 'created_at',
                    label: 'Registration',
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'contact',
                    label: 'Contact',
                },
                {
                    key: 'tracking',
                    label: 'Follow up',
                },
                {
                    key: 'status',
                    label: 'Status',
                },
                {
                    key: 'actions',
                    label: 'Actions',
                },
            ],
            filters: {
                advisor_id: '',
                limit: 50,
                page: 1,
                q: '',
                sort_by: null,
                sort_type: null,
                stage: '',
            },
            inititialLoading: true,
            loading: false,
            total_pages: null,
            confirmDeleteId: '',
            leadId: '',
            advisorData: {
                description: '',
                selected: '',
            },
            titleModal: '',
            leadIdLink: null,
            sending: false,
            sent: false,
        };
    },
    computed: {
        stages() {
            return stages;
        },
    },
    watch: {
        filters: {
            immediate: false,
            handler() {
                this.getLeads();
            },
            deep: true,
        },
    },
    methods: {
        updateStage({ id, stage }) {
            const searchIndex = this.accounts.findIndex(item => item.id === id);
            
            this.accounts[searchIndex].stage = stage;
        },
        async getLeads() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;
                const params = {
                    advisor_id: this.filters.advisor_id,
                    limit: this.filters.limit,
                    page: this.filters.page,
                    sort_by: this.filters.sort_by,
                    sort_type: this.filters.sort_type,
                    stage: this.filters.stage,
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/leads', { params });
                this.total_pages = data.pages;
                this.accounts = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false;
                }

                this.busy = false;
            }
        },
        async getAdvisors() {
            try {
                const { data } = await this.$axios.get('/_/admins?role=user');
                this.advisorsList = data.docs;
                this.advisorFilterList = data.docs.map(doc=>({
                    code: doc.id,
                    name: `${doc.first_name} ${doc.last_name}`
                }));
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
        editLead(id) {
            this.$router.push(`leads/register?leadId=${ id }`);
        },
        deleteLead(id) {
            this.confirmDeleteId = id;
            this.titleModal = 'Confirm delete';
            this.$refs["delete-modal"].show();
        },
        async confirmDelete() {
            this.sending = true;
            try {
                if(this.confirmDelete != '') {
                    await this.$axios.delete(`/_/leads/${ this.confirmDeleteId }`)
                        .then(() => {
                            this.accounts.splice(this.accounts.findIndex(i=>i.id === this.confirmDeleteId), 1)
                            this.$toast.success('The lead has been removed succesfully.');
                        });
                }
            }
            catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            }
            this.sending = false;
            this.closeConfirmModal();
        },
        closeConfirmModal() {
            this.confirmDeleteId = '';
            this.$refs["delete-modal"].hide();
            this.titleModal = '';
        },
        openAdvisorModal(id) {
            this.$refs["advisor-modal"].show();
            this.leadId = id;
        },
        closeAdvisorModal() {
            this.$refs["advisor-modal"].hide();
            this.leadId = '';
            this.advisorSelectedForUpdateLeads = '';
        },
        openModalSendEmail() {
            this.$refs["send-email-modal"].show();
        },
        closeModal() {
            this.$refs["send-email-modal"].hide();
            this.resetModal();
        },
        resetModal() {
            this.sending = false;
            this.leadIdLink = null;
        },
        setleadIdLink(id) {
            this.leadIdLink = id;
            this.openModalSendEmail()
        },
        async sendInvitationLink() {
            this.sending = true;
            try {
                await this.$axios.post(`/_/leads/${this.leadIdLink}/invite`)
                .then(()=>{
                    this.closeModal();
                    this.$toast.success('Link invitation has been send succesfully!')
                })
            }
            catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
            this.sending = false;
        },
        async updateLeadAdvisor() {
            this.sending = true;
            try {
                await this.$axios.put(`/_/leads/${this.leadId}`, { 
                    advisor_id: this.advisorSelectedForUpdateLeads 
                }).then(({ data })=>{
                    this.accounts.splice(this.accounts.findIndex(i=>i.id === data.id), 1, { ...data })
                })
                this.closeAdvisorModal();
                this.$toast.success('Advisor updated succesfully!');
            }
            catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
            this.sending = false;
        },
         async refreshLeadTable(){
            const params = {
                    page: this.filters.page,
                    limit: this.filters.limit,
                    sort_by: this.filters.sort_by,
                    sort_type: this.filters.sort_type,
                    advisor: this.filters.advisor
                };
            try {
                const { data } = await this.$axios.get('/_/leads', { params });
                this.total_pages = data.pages;
                this.accounts = data.docs;
            }
            catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        }
    },
    mounted() {
        this.getAdvisors();
    },
};
</script>
<script>

</script>

<style lang="scss">
.leads-list {
    &__copy-btn {
        border: none;
        border-radius: 5px;
    }

    &__flag {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000000, 0.15);
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }

    &__strong {
        font-weight: 600;
        color: $general-black;
    }

    &__contact-buttons {
        display: flex;
        gap: 5px;
    }

    &__contact-button {
        align-items: center;
        background-color: #eeeeee;
        border-radius: 4px;
        color: $white !important;
        display: flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        line-height: 1;
        padding: 8px;
        text-decoration: none;
        transition: 300ms all;
        width: 32px;

        &--whatsapp {
            background-color: #2ecc71;
            font-size: 18px;

            &:hover {
                background-color: rgba(#2ecc71, 0.8);
            }
        }

        &--phone {
            background-color: #0283cc;

            &:hover {
                background-color: rgba(#0283cc, 0.8);
            }
        }

        &--email {
            background-color: #ffb136;

            &:hover {
                background-color: rgba(#ffb136, 0.8);
            }
        }
    }

    &__stage-status {
        cursor: pointer;
    }
}
</style>
